<script>
import EdonProductNewView from './EdonProductNewView'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'EdonProductEditView',
  extends: EdonProductNewView,
  data () {
    return {
      view: 'edit'
    }
  },
  methods: {
    getEdonProduct () {
      this.$store.dispatch('edonProduct/fetchOne', this.$route.params.id)
        .then(() => {
          this.edonProduct = this.$store.getters['edonProduct/detail']
          this.selectedRubrics = this.edonProduct.rubrics.map(rubricId => {
            return this.$store.getters['rubric/rubricById'](rubricId)
          })
        })
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.$store.dispatch('edonProduct/update', this.prepareRequest())
        .then(() => {
          if (this.$store.getters['edonProduct/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['edonProduct/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  async created () {
    if (this.view === 'edit') {
      await this.loadRubrics()
    }
    this.getEdonProduct()
  }
}
</script>
